import './index.css';
import React from 'react';
import { Link } from "react-router-dom";

const ContactButtonComponent = () => {
    return (
        <div>
            <div className="container d-flex flex-wrap justify-content-center">
                <div className="d-block">
                    <span>AI O INTREBARE?</span>
                    <h2 className="gradient-text-top-bottom-purple my-3">Contacteaza-ne!</h2>
                    <span>Galati, Romania, str. Brailei, vis-a-vis Ultimul Leu. Telefon: 0734 167 740. E-mail: office@snapdesign.ro</span>
                    <div className="d-flex flex-wrap justify-content-center">
                        <Link to="/contact" className="action-button position-relative my-5 mx-0">Contact</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactButtonComponent;
