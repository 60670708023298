import './index.css';
import React from 'react';
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import PortfolioCardComponent from "../../Components/PortfolioCard";
import ContactButtonComponent from "../../Components/ContactButton";
import {Helmet} from "react-helmet";
import Header from "../../Layouts/Header";

const PortfolioPage = () => {
    const Diciassette = ['#000000', '#0A1215', '#C9AB81', '#D5D5D5'];
    const Protoncoding = ['#0A192F', '#63FDDA', '#8892B0'];
    const Dramirom = ['#F6F6F6', '#F0EEEF', '#272727', '#2789CA'];
    const SculeGalati = ['#F3F3F3', '#1EA951', '#212529'];
    const EpoxFloor = ['#282C34', '#374151', '#23C55E', '#FFFFFF'];

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Portofolio | Cele mai recente proiecte Snap Design</title>
                <meta name="description" content="Explorati portofoliul nostru de design si dezvoltare web si vedeti cum am ajutat afacerile sa isi imbunatateasca prezenta online. De la site-uri web personalizate la solutii de comert electronic, echipa noastra de la Snap Design livreaza designuri uimitoare si functionalitati exceptionale pentru a va ajuta sa va atingeti obiectivele de afaceri." />
                <link rel="canonical" href="/portofoliu" />
            </Helmet>
            <Navbar/>

            <Header
                smallTitle = 'Snap Design'
                bigTitle = 'PROIECTE'
            />
            <div className="container my-5 py-5 animate__animated animate__fadeIn">
                <div className="my-4 py-5">
                    <span className="my-4 py-5 gradient-text-top-bottom-purple">IATA CATEVA DINTRE</span>
                    <h2 className="my-3">Proiectele noastre</h2>
                    <div className="row my-5 py-5 px-3">

                    <PortfolioCardComponent
                        link = 'https://diciassette.ro'
                        title = 'Diciassette.ro'
                        description = 'Pizzerie Neonapoletana Galati'
                        image = {require('./../../Assets/Images/Portfolio/Diciassette/diciassette-front.png')}
                        colors = {Diciassette}
                    />

                    <PortfolioCardComponent
                        link = 'https://epoxfloor.ro'
                        title = 'Epoxfloor.ro'
                        description = 'Syntech Flooring - expertul tau in pardoseli'
                        image = {require('./../../Assets/Images/Portfolio/EpoxFloor/epoxfloor-front.png')}
                        colors = {EpoxFloor}
                    />

                    <PortfolioCardComponent
                        link = 'https://sculegalati.ro'
                        title = 'Sculegalati.ro'
                        description = 'Platforma inchirieri echipamente Galati'
                        image = {require('./../../Assets/Images/Portfolio/SculeGalati/sculegalati-front.png')}
                        colors = {SculeGalati}
                    />

                    <PortfolioCardComponent
                        link = 'https://protoncoding.com'
                        title = 'Protoncoding.com'
                        description = 'Agentie Web Development'
                        image = {require('./../../Assets/Images/Portfolio/Protoncoding/protoncoding-front.png')}
                        colors = {Protoncoding}
                    />

                    <PortfolioCardComponent
                        link = 'https://dramiromtrade.ro'
                        title = 'Dramiromtrade.ro'
                        description = 'Constructii si inchirieri utilaje multilanguage'
                        image = {require('./../../Assets/Images/Portfolio/Dramirom/dramirom-front.png')}
                        colors = {Dramirom}
                    />

                    </div>
                </div>
            </div>

            <ContactButtonComponent/>

            <div className="container">
                <Footer/>
            </div>
        </div>
    );
};

export default PortfolioPage;
