import './index.css';
import React from 'react';
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const ServicesComponent = () => {

    return (
        <div className="mt-5 px-2">
            <h1 className="gradient-text-top-bottom-purple cera-pro-black">with Snap Design.</h1>
            <div className="row">
                <div className="small gradient-text-top-bottom-purple mt-4">IATA CATEVA DINTRE</div>
                <div>
                    <h2 className="my-4">Serviciile noastre</h2>
                </div>
            </div>

            <div className="row mt-5">

                <div className="col-sm-12 col-md-4 mb-5 item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3">
                        <div className="card border-0 shadow p-5">
                            <div className="d-flex flex-wrap justify-content-center">
                                <div className="bullet-container gradient-bg-top-bottom-purple">
                                    <h2 className="text-white cera-pro-medium">1</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">

                                </div>
                                <div className="col-10 text-start">
                                    <div className="w-100 mt-4">
                                        <h5 className="cera-pro-medium">Web Development</h5>
                                    </div>
                                    <div className="w-100 mt-3">
                                        Web developmentul cuprinde toate aspectele construirii unui site web, inclusiv codarea, design-ul, crearea de conținut, functionalitati și administrarea de pe server.                                </div>
                                </div>
                            </div>
                        </div>
                    </AnimationOnScroll>
                </div>

                <div className="col-sm-12 col-md-4 mb-5 item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3" delay="300">
                        <div className="card border-0 shadow p-5">
                            <div className="d-flex flex-wrap justify-content-center">
                                <div className="bullet-container gradient-bg-top-bottom-purple">
                                    <h2 className="text-white cera-pro-medium">2</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">

                                </div>
                                <div className="col-10 text-start">
                                    <div className="w-100 mt-4">
                                        <h5 className="cera-pro-medium">Web Design</h5>
                                    </div>
                                    <div className="w-100 mt-3">
                                        Web design este procesul de creare a unor aspect vizual atractive și ușor de utilizat, responsive, pentru site-urile web, utilizând elemente precum culoare, tipografie și imagini.                                </div>
                                </div>
                            </div>
                        </div>
                    </AnimationOnScroll>
                </div>

                <div className="col-sm-12 col-md-4 mb-5 item">
                    <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3" delay="600">
                        <div className="card border-0 shadow p-5">
                            <div className="d-flex flex-wrap justify-content-center">
                                <div className="bullet-container gradient-bg-top-bottom-purple">
                                    <h2 className="text-white cera-pro-medium">3</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">

                                </div>
                                <div className="col-10 text-start">
                                    <div className="w-100 mt-4">
                                        <h5 className="cera-pro-medium">Web Hosting</h5>
                                    </div>
                                    <div className="w-100 mt-3">
                                        Web hosting este serviciul care oferă tehnologia și suportul necesar pentru a face un site web accesibil pe internet, acest lucru include serverele, lățimea de bandă și suportul tehnic.                                </div>
                                </div>
                            </div>
                        </div>
                    </AnimationOnScroll>
                </div>

                <div className="mt-5 d-flex justify-content-center">
                    <Link to="/servicii" className="action-button">Citeste mai multe</Link>
                </div>

            </div>
        </div>
    );
};

export default ServicesComponent;
