import './App.css';
import WebRoutes from "./routes/web";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import $ from 'jquery';
import "animate.css/animate.min.css";
import React, { useEffect, useState } from "react";
import { BsChevronUp } from "react-icons/bs";
import CookieConsent from "react-cookie-consent";

function App() {

    $(document).ready(function () {
        var mouseX = 0, mouseY = 0;
        var xp = 0, yp = 0;

        $(document).mousemove(function (e) {
            mouseX = e.pageX - 30;
            mouseY = e.pageY - 30;
            $('#circularcursor').css({
                left: e.pageX,
                top: e.pageY
            });
        });

        setInterval(function () {
            xp += ((mouseX - xp) / 6);
            yp += ((mouseY - yp) / 6);
            $("#mouse-circle").css({ left: xp + 'px', top: yp + 'px' });
        }, 20);

    });

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    // This function will scroll the window to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };



    return (
        <div className="App">
            {/*<span id="mouse-circle" className="mouse-circle"></span>*/}
            {/*<div id="circularcursor"></div>*/}

            <CookieConsent
                location="bottom"
                buttonText="Accept Cookies"
                cookieName="SnapDesign Cookies"
                style={{ background: "#2B373B", zIndex: "9999999999", textAlign: "start" }}
                buttonStyle={{ color: "white", background: "#8654d8", fontSize: "16px" }}
                expires={150}
            >
                Pentru o mai buna experienta, acest site foloseste cookies. Pentru mai multe deralii citeste <a href="/politica-cookie" className="text-white text-decoration-none" target="_blank">Politica Cookie</a>{" "}
            </CookieConsent>

            <WebRoutes />
            {/* {showButton && (
            <button onClick={scrollToTop} className="back-to-top">
                <BsChevronUp/>
            </button>
        )} */}
            {/* {showButton && (
                <a href="/contact" className="mobile-hidden">
                    <div className="contact-tag shadow">Angajeaza-ne</div>
                </a>
            )} */}


        </div>
    );
}

export default App;
