import './index.css';
import React from 'react';

const WelcomeComponent = (props) => {

    return (
        <div className="px-2">
            <div className="row">
                <div className="col-sm-12 col-md-5 text-start my-md-5">
                    <h1 className="cera-pro-black gradient-text-top-bottom-purple">
                        {props.titleBig}
                    </h1>
                    <div>
                        <h2 className="cera-pro-medium mt-4 mt-md-5">
                            {props.titleMedium}
                        </h2>
                    </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-sm-12 col-md-6 my-5 d-flex flex-wrap align-items-center text-start">
                    <span className="d-block my-md-4 font-18">
                        {props.content}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WelcomeComponent;
