import './index.css';
import React from 'react';
import { AnimationOnScroll } from "react-animation-on-scroll";

const PowerhousesComponent = () => {

    return (
        <div className="pt-5 mt-4 mt-md-5">
            <div className="home-bg py-5 mt-5 mt-md-5">
                <div className="container my-5">
                    <h3>Construim sisteme</h3>
                    <h1 className="cera-pro-black gradient-text-top-bottom-purple pb-3">Digitale Complete</h1>
                    <div className="row my-5">
                        <div className="col-sm-12 col-md-4 my-5 item">
                            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3">
                                <div className="card border-0 shadow p-5 auto-height">
                                    <span className="mb-4"><img width="45" src={require('./../../Assets/Images/Icons/speed.png')} /></span>
                                    <h3 className="cera-pro-medium gradient-text-top-bottom-purple">Rapid</h3>
                                    <div className="ps-4 mt-3 text-start">
                                        Site-ul tau trebuie sa fie rapid! Este recomandat ca vizitatorii sa poata naviga pe site in mai putin de 4 secunde. Orice intarziere a randarii acestuia poate reduce exponential numarul vizitatorilor si implicit al clientilor.
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                        <div className="col-sm-12 col-md-4 my-5 item">
                            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3" delay="300">
                                <div className="card border-0 shadow p-5 auto-height">
                                    <span className="mb-4"><img width="45" src={require('./../../Assets/Images/Icons/intuitive.png')} /></span>
                                    <h3 className="cera-pro-medium gradient-text-top-bottom-purple">Intuitiv</h3>
                                    <div className="ps-4 mt-3 text-start">
                                        Vizitatorii website-ului dvs trebuie sa aiba o experienta de navigare pozitiva. Utilizatorii care acceseaza site-ul de pe mobil sau tableta se asteapta sa poata gasi usor informatiile, in general acestia isi fac o opinie in aproximativ 0.5 secunde de cand pagina este incarcata.
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                        <div className="col-sm-12 col-md-4 my-5 item">
                            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3" delay="600">
                                <div className="card border-0 shadow p-5 auto-height">
                                    <span className="mb-4"><img width="45" src={require('./../../Assets/Images/Icons/responsive.png')} /></span>
                                    <h3 className="cera-pro-medium gradient-text-top-bottom-purple">Responsive</h3>
                                    <div className="ps-4 mt-3 text-start">
                                        Se estimeaza ca 60% din totalul de trafic pe Internet se efectueaza de pe telefonul mobil sau tableta, de aceea este necesar ca site-ul dvs sa fie optimizat pentru aceste medii. O interfata mobile responsive bine optimizata pentru site-ul dvs. face ca vizitatorii sa aiba o experienta optima si acestia sa se transforme in cumparatori.
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PowerhousesComponent;
