import './index.css';
import React, {useRef} from 'react';
import { motion, useScroll, useTransform } from "framer-motion";
import {AnimationOnScroll} from "react-animation-on-scroll";

const MacbookComponent = () => {

    return (
        <div className="mb-5 pb-0 mb-md-3 pb-md-2">
            <div className="mb-5 py-0 py-md-5">
                <span className="gradient-text-top-bottom-purple">TOATE SITE-URILE NOASTRE SUNT</span>
                <h2 className="my-0 my-md-4">Responsive pe orice device</h2>
            </div>
            <div>
                <AnimationOnScroll animateIn="animate__zoomIn" animateOnce duration="0.5">
                    <div className="macbook scale-laptop" >
                        <div className="screen-laptop">
                            <div className="viewport-laptop"
                                 style={{ backgroundImage: 'url(' + require('./../../Assets/Images/Portfolio/Diciassette/diciassette-front.png') + ')' }}>
                            </div>
                        </div>
                        <div className="base"></div>
                        <div className="notch"></div>
                    </div>
                </AnimationOnScroll>
            </div>
        </div>
    );
};

export default MacbookComponent;
