import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from "../Pages/Home";
import ServicesPage from "../Pages/Services";
import ProductsPage from "../Pages/Products";
import PortfolioPage from "../Pages/Portfolio";
import ContactPage from "../Pages/Contact";
import StudiesPage from "../Pages/Studies";
import GoToTop from "../Config/GoToTop";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import CookiePolicy from "../Pages/CookiePolicy";

const WebRoutes = () => {
    return (
        <div>
            <GoToTop/>
            <Routes>
                <Route path = '/' exact element={<HomePage/>} />
                <Route path = '/servicii' exact element={<ServicesPage/>} />
                {/*<Route path = '/produse' exact element={<ProductsPage/>} />*/}
                <Route path = '/portofoliu' exact element={<PortfolioPage/>} />
                {/*<Route path = '/studii-de-caz' exact element={<StudiesPage/>} />*/}
                <Route path = '/contact' exact element={<ContactPage/>} />
                <Route path = 'politica-confidentialitate' exact element={<PrivacyPolicy/>} />
                <Route path = 'politica-cookie' exact element={<CookiePolicy/>} />
            </Routes>
        </div>
    );
};

export default WebRoutes;
