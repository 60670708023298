import './index.css';
import React from 'react';
import Tilt from "react-parallax-tilt";
import {Link} from "react-router-dom";

const Hero = () => {
    return (
        <div className="hero d-flex align-items-center">
            <div className="w-100 d-flex flex-wrap justify-content-center">
                <div className="row px-0 mx-0 px-sm-5 mx-sm-5 max-width-hero">
                    <div className="col-md-4 d-flex align-items-center p-5">
                        <div className="d-flex flex-wrap justify-content-center align-items-center image-holder">
                            <div className="text-start text-hero animate__animated animate__fadeIn">
                                <h1 className="cera-pro-medium text-white huge-title text-start my-4 pt-5 pt-md-0">Welcome to Snap Design Agency</h1>
                                <span className="font-18 text-white">Web Development site-uri prezentare, magazine online,
                                    landing pages, teme si plugin-uri Shopify, Wordpress, Prestashop,
                                    scripturi si softuri adaptate nevoilor tale, totul la un click distanta
                                    </span>
                                <div className="mt-5">
                                    <Link to="/contact" className="call-hero-btn cera-pro-medium">Contacteaza-ne acum</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-wrap align-items-center justify-content-center my-4">
                        <div className="animate__animated animate__fadeIn">
                        <div className="d-flex flex-wrap justify-content-center align-items-center image-holder">

                            <div className="dotted-bg-up mobile-hidden"></div>

                            <Tilt tiltMaxAngleX={7} tiltMaxAngleY={5} perspective={1000} >
                                <img className="mobile-hidden" draggable="false" width="100%" src={require('./../../Assets/Images/Content/hero-screen.png')}/>
                            </Tilt>

                            <div className="dotted-bg-down mobile-hidden"></div>

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
