import './index.css';
import React from 'react';
import { SlSocialFacebook } from "react-icons/sl";
import { SlSocialTwitter } from "react-icons/sl";
import { SlSocialInstagram } from "react-icons/sl";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer px-1">
            <div className="row">
                <div className="text-start">
                    <h2><Link to="/" className="text-decoration-none text-dark">span design</Link></h2>
                </div>
                <div className="col-sm-12 col-md-4 text-start">
                    <span>© Copyright {new Date().getFullYear()} SnapDesign</span>
                    <a href="/politica-confidentialitate" className="small d-block my-2 purple-link">Politica de confidentialitate</a>
                    <a href="/politica-cookie" className="small d-block my-2 purple-link">Politica Cookies</a>
                </div>
                <div className="col-sm-12 col-md-4 mt-2 mt-md-0">
                    <div className="pt-3">
                        <a className="p-2" href="https://ec.europa.eu/consumers/odr" target="_blank"><img width="160" src="https://anpc.ro/wp-content/uploads/2022/08/pictogramaSOL.png"/></a>
                        <a className="p-2" href="https://anpc.ro/ce-este-sal/" target="_blank"><img width="160" src="https://anpc.ro/wp-content/uploads/2022/07/SAL-PICTOGRAMA.png"/></a>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 text-sm-start text-md-end mt-4 mt-md-0">
                    Conecteaza-te cu noi
                    <div className="row">
                        <div className="mt-1">
                            <a href="https://www.facebook.com/SnapDesignDevel" className="p-3 footer-socials" target="_blank"><SlSocialFacebook/></a>
                            <a href="https://twitter.com/snapdesignro" className="p-3 footer-socials" target="_blank"><SlSocialTwitter/></a>
                            <a href="https://www.instagram.com/snapdesigndev/" className="p-3 footer-socials" target="_blank"><SlSocialInstagram/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
