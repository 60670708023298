import './index.css';
import React from 'react';
import $ from 'jquery';
const TestimonialComponent = () => {

    return (
        <div>
            <div className="mb-5">
                <span className="gradient-text-top-bottom-purple">FEEDBACK CLIENTI</span>
                <h2 className="mt-4">Apecieri din<br /> partea Clientilor</h2>
            </div>
            <div className="slider">
                <input type="radio" name="slider" title="slide1" className="slider__nav" />
                <input type="radio" name="slider" title="slide2" className="slider__nav" />
                <input type="radio" name="slider" title="slide3" className="slider__nav" />
                <input type="radio" name="slider" title="slide4" className="slider__nav" />
                <div className="slider__inner">
                    <div className="slider__contents"><i className="slider__image fa fa-codepen"></i>
                        <div className="card border-0 shadow col-12 col-md-7 testimonials-card text-center testimonials-holder">
                            <img draggable="false" src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="testimonial-image shadow" height="130" width="130" />
                            <h2 className="slider__caption mt-5 pt-5">DICIASSETTE</h2>
                            <p className="p-5 pt-4">
                                Sunt incantat de serviciile excepționale. Echipa Snap Design a creat un site modern si usor de utilizat care reprezinta perfect brandul meu. Au fost receptivi si atenti la nevoile mele pe tot parcursul procesului. Recomand cu incredere serviciile lor.                            </p>
                            <div className="mb-5">
                                <span className="gradient-text-top-bottom-purple cera-pro-bold">Iancu M</span>
                            </div>
                        </div>
                    </div>
                    <div className="slider__contents"><i className="slider__image fa fa-codepen"></i>
                        <div className="card border-0 rounded-0 shadow col-12 col-md-7 testimonials-card text-center">
                            <img draggable="false" src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="testimonial-image shadow" height="130" width="130" />
                            <h2 className="slider__caption mt-5 pt-5">SYNTECH FLOOR</h2>
                            <p className="p-5 pt-4">
                                Snap Design facut intregul proces usor. De la consultarea initiala pana la lansarea finala a site-ului, au inteles nevoile mele si au livrat un site care arata uimitor si functioneaza perfect. Vad deja o crestere a traficului. Mulțumesc!
                            </p>
                            <div className="mb-5">
                                <span className="gradient-text-top-bottom-purple cera-pro-bold">Hristea G</span>
                            </div>
                        </div>
                    </div>
                    <div className="slider__contents"><i className="slider__image fa fa-codepen"></i>
                        <div className="card border-0 rounded-0 shadow col-12 col-md-7 testimonials-card text-center">
                            <img draggable="false" src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="testimonial-image shadow" height="130" width="130" />
                            <h2 className="slider__caption mt-5 pt-5">DAVS GAL RED CAR</h2>
                            <p className="p-5 pt-4">
                                Am avut o experienta buna lucrand cu aceasta companie de web design. Ei au dat viata viziunii mele si au livrat o aplicatie care a depasit asteptarile mele. Echipa a fost profesionista, calificata si comunicativa. Sunt extrem de mulțumita de produsul final.
                            </p>
                            <div className="mb-5">
                                <span className="gradient-text-top-bottom-purple cera-pro-bold">Lazar D</span>
                            </div>
                        </div>
                    </div>
                    <div className="slider__contents"><i className="slider__image fa fa-codepen"></i>
                        <div className="card border-0 rounded-0 shadow col-12 col-md-7 testimonials-card text-center">
                            <img draggable="false" src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className="testimonial-image shadow" height="130" width="130" />
                            <h2 className="slider__caption mt-5 pt-5">PROTONCODING</h2>
                            <p className="p-5 pt-4">
                                The quality of work from company is truly remarkable. Their team created a website that not only looks great but is also optimized for SEO and user experience. Their attention to detail and dedication to their craft really shines through. I highly recommend their services.
                            </p>
                            <div className="mb-5">
                                <span className="gradient-text-top-bottom-purple cera-pro-bold">Joey B</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TestimonialComponent;
