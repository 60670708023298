import './index.css';
import React from 'react';
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import {Helmet} from "react-helmet";
import Header from "../../Layouts/Header";

const CookiePolicy = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Snap Design | Politica Cookie</title>
                <meta name="description" content="" />
                <meta name="robots" content="noindex" />
                <link rel="canonical" href="/politica-cookie" />
            </Helmet>
            <Navbar/>
            <Header
                smallTitle = 'Snap Design'
                bigTitle = 'POLITICA COOKIE'
            />

            <div className="container my-5">

                <h1 className="my-5 pt-5 text-start cera-pro-black gradient-text-top-bottom-purple">Politica Cookie</h1>

                <div className="my-5 text-start font-18">
                    Această Politică privind modulele cookie explică modul în care Snap Design utilizează modulele cookie pe site-ul nostru web, https://snapdesign.ro.
                </div>
                <div className="mt-5 text-start font-18 gradient-text-top-bottom-purple cera-pro-medium">
                    Ce sunt modulele cookie?
                </div>
                <div className="mb-5 mt-3 text-start font-18">
                    Modulele cookie sunt fișiere mici de date care sunt plasate pe computerul sau dispozitivul dvs. mobil atunci când vizitați un site web. Modulele cookie sunt larg utilizate de proprietarii de site-uri web pentru a face site-urile lor să funcționeze sau să funcționeze mai eficient, precum și pentru a furniza informații de raportare.
                </div>
                <div className="mt-5 text-start font-18 gradient-text-top-bottom-purple cera-pro-medium">
                    Cum folosim modulele cookie?
                </div>
                <div className="mb-5 mt-3 text-start font-18">
                    Utilizăm modulele cookie într-o varietate de scopuri, inclusiv pentru a:
                    <div className="ps-3">
                    1. Furniza funcționalitate pe site-ul nostru web<br/>
                    2. Înțelege și analiza modul în care utilizați site-ul nostru web
                    </div>
                </div>

                <div className="mt-5 text-start font-18 gradient-text-top-bottom-purple cera-pro-medium">
                    Tipurile de module cookie pe care le folosim:
                </div>
                <div className="mb-5 mt-3 text-start font-18">
                    <div className="ps-3">
                        1. Module cookie strict necesare: Aceste module cookie sunt necesare pentru ca site-ul să funcționeze și nu pot fi dezactivate în sistemele noastre. De obicei, sunt setate doar ca răspuns la acțiuni realizate de dvs., care reprezintă o solicitare de servicii, cum ar fi setarea preferințelor de confidențialitate, conectarea sau completarea formularelor.
                    </div>
                    <div className="ps-3">
                        2. Module cookie analitice/de performanță: Aceste module cookie ne permit să recunoaștem și să numărăm numărul de vizitatori și să vedem cum se deplasează vizitatorii pe site-ul nostru web atunci când îl utilizează. Acest lucru ne ajută să îmbunătățim modul în care funcționează site-ul nostru web, de exemplu, asigurându-ne că utilizatorii găsesc ușor ceea ce caută.
                    </div>
                </div>
                <div className="mt-5 text-start font-18 gradient-text-top-bottom-purple cera-pro-medium">
                    Cum să controlați modulele cookie?
                </div>
                <div className="mb-5 mt-3 text-start font-18">
                    Majoritatea browserelor web vă permit să controlați modulele cookie prin preferințele lor de setări. Cu toate acestea, vă rugăm să rețineți că dezactivarea modulelor cookie poate duce la imposibilitatea de a utiliza toate funcțiile site-ului nostru web.
                </div>
            </div>

            <div className="container">
                <Footer/>
            </div>
        </div>
    );
};

export default CookiePolicy;
