import './index.css';
import React from 'react';
import IphoneComponent from "../../Components/Iphone";
import Header from "../../Layouts/Hero";
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import ServicesComponent from "../../Components/Services";
import CounterComponent from "../../Components/Counter";
import TechnologiesComponent from "../../Components/Technologies";
import WelcomeComponent from "../../Components/Welcome";
import MacbookComponent from "../../Components/Macbook";
import TestimonialComponent from "../../Components/Testimonials";
import { SlArrowDown } from "react-icons/sl";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { useRef } from 'react';
import { Helmet } from "react-helmet";
import DesignRush from '../../Assets/Images/design-rush.svg';

const HomePage = () => {
    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Snap Design | Creare site ieftin si rapid</title>
                <meta name="description" content="Snap Design este o companie de web design si dezvoltare care creeaza site-uri web responsive si de inalta calitate pentru afaceri si persoane fizice. Echipa noastra experimentata de designeri si dezvoltatori este dedicata furnizarii de solutii inovatoare, adaptate nevoilor unice ale fiecarui client. Contactati-ne pentru a afla mai multe despre cum va putem ajuta sa va stabiliți o prezenta puternica online si sa va atingeti audienta tinta." />
                <link rel="canonical" href="/" />
            </Helmet>
            <Navbar />
            <Header />
            <div className="w-100 d-flex flex-wrap justify-content-center">
                <div className="row scroll-down mobile-hidden d-block">
                    <button className="bg-transparent border-0" onClick={handleClick}>
                        <span className="scroll-text mobile-hidden"> -------------- scroll</span>
                    </button>
                </div>
            </div>

            <div className="home-bg" ref={ref}>
                <div className="container py-5">
                    <ServicesComponent />
                </div>

                <div className="my-5 pb-5">
                    <CounterComponent />
                </div>
            </div>

            <div className="down-chevron-content shadow">
                <SlArrowDown />
            </div>

            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce duration="0.5">
                <div className="container my-5 py-5">
                    <WelcomeComponent
                        titleBig='go Online,'
                        titleMedium='Snap Design iti ofera servicii complete de web design si development potrivite nevoilor tale.'
                        content={['Va ofera servicii de web development, pachete de web design pentru situri de prezentare, magazine online, implementari CMS (Wordpress, Prestashop, Shopify, OctoberCMS), logo & branding, dar si servicii conexe, cum sunt - optimzarea SEO si mentenanta ', <span className='gradient-text-top-bottom-purple'> la preturi imbatabile! </span>, <span className="d-block mt-3"> Toate proiectele noastre de web design indeplinesc cerintele pietei avandun design simplu, elegant si intuitiv, design ce totodata este responsive atat pentru tablete cat si pentru telefoane mobile.</span>]}
                    />
                </div>
            </AnimationOnScroll>

            <div className="container mb-5 pb-5">
                <MacbookComponent />
            </div>

            <div className="container my-5 py-5">
                <IphoneComponent />
            </div>

            <div className="my-5 py-5 bg-light-gray">
                <div className="my-5 py-5 container">
                    <div className="gradient-text-top-bottom-purple">
                        EXPERIENTA DIGITALA INTELIGENTA CU SNAP DESIGN
                    </div>
                    <div className="my-4 container px-0 px-md-5">
                        <h2 className="cera-pro-bold">Descopera solutiile noastre digitale care te vor ajuta sa iti imbunatatesti prezenta online.</h2>
                    </div>
                    <div className="mt-5 container d-flex justify-content-center">
                        <Link to="/contact" className="action-button">CONTACTEAZA-NE</Link>
                    </div>
                </div>
            </div>

            <div className="gradient-gray">
                <div className='mt-4 pt-5'>
                    <a href="https://www.designrush.com/agency/profile/snap-design" target='_blank'>
                        <img src={DesignRush} alt="DesignRush" />
                    </a>
                </div>
                <div className="container my-5 pb-5 pt-1">
                    <TechnologiesComponent />
                </div>
                <hr />
                <div className="container my-5 py-5 overflow-hidden">
                    <TestimonialComponent />
                </div>
            </div>

            <div className="container my-5">
                <Footer />
            </div>
        </div>
    );
};

export default HomePage;
