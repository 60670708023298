import './index.css';
import React from 'react';
import {AnimationOnScroll} from "react-animation-on-scroll";


const WideContentComponent = (props) => {

    return (
        <div>
            <div>
                <div className="my-5">
                    <span className="gradient-text-top-bottom-purple">{props.smallTitle}</span>
                    <h1 className="my-4">{props.bigTitle}</h1>
                </div>
                <span className="font-18">{props.description}</span>
            </div>
        </div>
    );
};

export default WideContentComponent;
