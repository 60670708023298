import './index.css';
import React from 'react';

const PortfolioCardComponent = (props) => {
    const link = props.link;
    const image = props.image;
    const title = props.title;
    const description = props.description;
    const colors = props.colors;

    return (
        <div className="my-3 col-sm-12 col-md-6 p-2">
            <div className="card border-0 shadow project-holder overflow-hidden m-0">
                <a href={props.link} className="portfolio-link" target="_blank">
                    <div className="backdrop-custom pt-5 d-flex flex-wrap align-items-center justify-content-center">
                        <div className="d-block">
                            <span className="portfolio-title">{props.title}</span>
                            <div className="w-100 d-flex gap-2 mt-2">
                                Palette:
                                {colors.map(palette => {
                                    return <div className="palette shadow" style={{ background: palette }}></div>
                                })}
                            </div>
                        </div>
                        <div className="d-block w-100 px-5">{props.description}</div>
                    </div>
                    <div className="portfolio-image" style={{ backgroundImage: 'url(' + props.image + ')' }}></div>
                </a>
            </div>
        </div>
    );
};

export default PortfolioCardComponent;
