import './index.css';
import React from 'react';
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import { Helmet } from "react-helmet";
import axios from "axios";
import Header from "../../Layouts/Header";

const API_PATH = 'https://snapdesign.ro/mail_send_script/index.php';

class ContactPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            budget: '',
            short: '',
            message: '',
            mailSent: false,
            error: null
        }
    }

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: result.data.sent,
                    name: '',
                    email: '',
                    short: '',
                    budget: '',
                    message: '',
                })
            })
            .catch(error => this.setState({ error: error.message }));
    };

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Snap Design | Creare site ieftin si rapid</title>
                    <meta name="description"
                        content="Contactati-ne pentru a discuta despre cum putem ajuta afacerea dvs. sa prospere online. Suntem aici pentru a va asculta si pentru a va oferi solutii personalizate de design si dezvoltare web." />
                    <link rel="canonical" href="/contact" />
                </Helmet>
                <Navbar />
                <Header
                    smallTitle='Snap Design'
                    bigTitle='CONTACT'
                />

                <div className="mt-5 pt-5 animate__animated animate__fadeIn">
                    <div className="container mt-5">
                        <h5 className="gradient-text-top-bottom-purple my-4">SALUT</h5>
                        <h2 className="mb-5">Hai sa facem cunostinta!</h2>

                        <div className="row px-3 my-5">
                            <div className="px-3 d-flex justify-content-center">
                                <a href="tel:+40734167740" target="_blank" className="btn">Suna acum +4 0734 167 740</a>
                            </div>

                            <div className="px-3 d-flex justify-content-center">
                                <a href="https://wa.me/+40734167740" target="_blank" className="btn">Whatsapp +4 0734 167 740</a>
                            </div>

                            <div className="px-3 d-flex justify-content-center">
                                <a href="mailto:office@snapdesign.ro" className="btn">Trimite email:
                                    office@snapdesign.ro</a>
                            </div>
                        </div>

                        <h4>Sau ne poti lasa un mesaj chiar aici</h4>

                        <form action="#">

                            <div className="bg-success mb-4 text-center d-none" id="response">
                                Mesajul tau a fost trimis cu success!
                            </div>

                            <div className="row p-3">
                                <div className="col-12 col-md-6 text-start p-3">
                                    <div className="form-group">
                                        <label htmlFor="name_input">Nume</label>
                                        <input type="text" name="name_input" id="name" autoComplete="off" required
                                            value={this.state.name}
                                            onChange={e => this.setState({ name: e.target.value })}
                                            className="form-control custom-input"></input>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 text-start p-3">
                                    <div className="form-group">
                                        <label htmlFor="email_input">Adresa Email</label>
                                        <input type="email" name="email_input"
                                            id="email" autoComplete="off" required
                                            value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value })}
                                            className="form-control custom-input"></input>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 text-start p-3">
                                    <div className="form-group">
                                        <label htmlFor="short_description_input">Scurta Descriere</label>
                                        <input type="text" name="short_description_input"
                                            id="short"
                                            autoComplete="off" required
                                            onChange={e => this.setState({ short: e.target.value })}
                                            value={this.state.short}
                                            className="form-control custom-input"></input>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 text-start p-3">
                                    <div className="form-group">
                                        <label htmlFor="budget_input">Buget Alocat (euro)</label>
                                        <input type="number" name="budget_input"
                                            id="budget"
                                            autoComplete="off" required
                                            onChange={e => this.setState({ budget: e.target.value })}
                                            value={this.state.budget}
                                            className="form-control custom-input"></input>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 text-start p-3">
                                    <div className="form-group">
                                        <label htmlFor="message">Mesaj Detaliat</label>
                                        <textarea rows="7" name="message"
                                            id="message"
                                            autoComplete="off" required
                                            onChange={e => this.setState({ message: e.target.value })}
                                            value={this.state.message}
                                            className="form-control custom-input"></textarea>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mt-5">
                                    <button type="submit" onClick={e => this.handleFormSubmit(e)} className="action-button border-0">Trimite Mesaj</button>
                                </div>
                            </div>
                            <div>
                                {this.state.mailSent &&
                                    <div>Multumim pentru ca ne-ai contactat.</div>
                                }
                            </div>
                        </form>
                    </div>
                </div>

                <div className="container py-5">
                    <Footer />
                </div>
            </div>
        );
    };
};

export default ContactPage;
