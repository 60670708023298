import './index.css';
import React from 'react';
import { SlArrowDown } from "react-icons/sl";

const Header = (props) => {
    return (
        <div className="header d-flex flex-wrap align-items-center justify-content-center">


            <div className="dotted-bg-header-up"></div>

            <div className="container animate__animated animate__fadeIn">
                <h2 className="text-white">{props.smallTitle}</h2>
                <h1 className="cera-pro-black text-white text-large">{props.bigTitle}</h1>
                <h4 className="cera-pro-black text-white cera-pro-light">{props.description}</h4>
                <div className="down-chevron shadow">
                    <SlArrowDown />
                </div>
            </div>


        </div>
    );
};

export default Header;
