import './index.css';
import React, {useRef} from 'react';
import CountUp from 'react-countup';
import { useInView } from 'framer-motion';

const CounterComponent = () => {

    const ref = useRef();
    const enterCount = useInView(ref, { once: true });

    return (
        <div className="my-0 my-md-5">
            <div className="px-2">
                <div className="container" ref={ref}>
                    <div className="row d-flex flex-wrap align-items-end">
                        <div className="col-sm-12 col-md-3 my-5 text-center text-md-end">
                            <h2 className="cera-pro-black gradient-text-top-bottom-purple counter-medium">
                                { enterCount > 0 ?
                                    <div className="cera-pro-black"><CountUp end={225} key='in' className="cera-pro-black"/>+</div>:
                                    <div className="cera-pro-black"><CountUp end={225} key='out' className="cera-pro-black"/>+</div>
                                }
                            </h2>
                            <span className="mt-1 d-block cera-pro-medium">PROIECTE REALIZATE</span>
                            <span className="d-block my-3 text-center text-md-end">
                                Ajutam afacerile sa atinga obiectivele lor prin proiecte personalizate.
                            </span>
                        </div>
                        <div className="col-sm-12 col-md-6 my-5">
                            <span className="cera-pro-bold gradient-text-top-bottom-purple counter-large">1%</span>
                            <h5 className="d-block cera-pro-medium">TOP 1 LA SUTA DINTRE AGENTIILE DE WEB<br/> DEVELOPMENT DIN ROMANIA</h5>
                        </div>
                        <div className="col-sm-12 col-md-3 my-5 text-center text-md-start">
                            <h2 className="cera-pro-black gradient-text-top-bottom-purple counter-medium">
                                { enterCount > 0 ?
                                    <div className="cera-pro-black"><CountUp end={213} key='in' className="cera-pro-black"/>+</div>:
                                    <div className="cera-pro-black"><CountUp end={213} key='out' className="cera-pro-black"/>+</div>
                                }                            </h2>
                            <span className="mt-1 d-block cera-pro-medium">ONLINE BRANDING</span>
                            <span className="d-block my-3 text-center text-md-start">
                                Ajutam clientii nostri sa isi creasca prezenta si vizibilitatea prin branding eficient.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterComponent;
