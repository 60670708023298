import './index.css';
import React from 'react';
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import {Helmet} from "react-helmet";
import Header from "../../Layouts/Header";

const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Snap Design | Politica de confidentialitate</title>
                <meta name="description" content="" />
                <meta name="robots" content="noindex" />
                <link rel="canonical" href="/politica-confidentialitate" />
            </Helmet>
            <Navbar/>
            <Header
                smallTitle = 'Snap Design'
                bigTitle = 'POLITICA NOASTRA'
            />

            <div className="container">
                <h1 className="my-5 pt-5 text-start cera-pro-black gradient-text-top-bottom-purple">Politica de Confidentialitate</h1>

                <div className="my-5 text-start font-18">
                    Bine ați venit la Snap Design! Prin utilizarea site-ului nostru web, sunteți de acord cu următorii termeni de utilizare. Dacă nu sunteți de acord cu acești termeni, vă rugăm să nu utilizați site-ul nostru web.
                </div>

                <div className="my-3 text-start font-18">
                    <span className="gradient-text-top-bottom-purple cera-pro-medium">1. Utilizarea conținutului:</span> Tot conținutul de pe site-ul nostru web este deținut de Snap Design și este protejat de drepturile de autor și alte legi și tratate aplicabile. Aveți permisiunea de a utiliza conținutul nostru numai în scopuri personale și non-comerciale. Orice altă utilizare a conținutului nostru, inclusiv copierea, modificarea, distribuirea sau republicarea, este interzisă fără permisiunea noastră scrisă.
                </div>
                <div className="my-3 text-start font-18">
                    <span className="gradient-text-top-bottom-purple cera-pro-medium">2. Informații personale:</span> Prin utilizarea site-ului nostru web, sunteți de acord cu politica noastră de confidențialitate privind colectarea, utilizarea și divulgarea informațiilor dvs. personale. Vă rugăm să citiți politica noastră de confidențialitate pentru a afla mai multe.
                </div>
                <div className="my-3 text-start font-18">
                    <span className="gradient-text-top-bottom-purple cera-pro-medium">3. Limitarea responsabilității noastre:</span> Nu garantăm că site-ul nostru web va fi întotdeauna disponibil sau va fi lipsit de erori sau viruși. Nu suntem responsabili pentru orice daune directe, indirecte, accidentale sau de altă natură rezultate din utilizarea sau incapacitatea de a utiliza site-ul nostru web.
                </div>
                <div className="my-3 text-start font-18">
                    <span className="gradient-text-top-bottom-purple cera-pro-medium">4. Legi aplicabile:</span> Acești termeni de utilizare vor fi guvernate și interpretate în conformitate cu legile statului în care se află sediul nostru.
                </div>
                <div className="my-3 text-start font-18">
                    <span className="gradient-text-top-bottom-purple cera-pro-medium">5. Modificări ale termenilor:</span> Ne rezervăm dreptul de a modifica acești termeni de utilizare în orice moment. Prin continuarea utilizării site-ului nostru web după modificările aduse, sunteți de acord să fiți obligat de noile termeni.
                </div>
                <div className="my-5 text-start font-18">
                    Vă mulțumim pentru utilizarea site-ului nostru web.
                </div>
            </div>

            <div className="container">
                <Footer/>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
