import './index.css';
import React from 'react';

const TechnologiesComponent = () => {


    return (
        <div className="my-5 pb-5 overflow-hidden">
            <div>
                <span className="gradient-text-top-bottom-purple">CUM FACEM TOTUL POSIBIL?</span>
                <h2 className="my-4">Tehnologii folosite</h2>
                <div className="row mt-5 align-items-center">
                    <div className="col-6 col-md-3 border-box border-0-top border-0-left">
                        <img draggable="false" className="greyscale m-5 p-1" width="144" src={require('./../../Assets/Images/Technologies/react.png')} />
                    </div>

                    <div className="col-6 col-md-3 border-box border-0-top">
                        <img draggable="false" className="greyscale m-5 p-1" width="170" src={require('./../../Assets/Images/Technologies/node.png')} />
                    </div>

                    <div className="col-6 col-md-3 border-box border-0-top">
                        <img draggable="false" className="greyscale m-5 p-1" width="170" src={require('./../../Assets/Images/Technologies/laravel.png')} />
                    </div>

                    <div className="col-6 col-md-3 border-box border-0-top border-0-right">
                        <img draggable="false" className="greyscale m-5 p-1" width="170" src={require('./../../Assets/Images/Technologies/shopify.png')} />
                    </div>

                    <div className="col-6 col-md-3 border-box border-0-left border-0-bottom">
                        <img draggable="false" className="greyscale m-5 p-1" width="170" src={require('./../../Assets/Images/Technologies/woocommerce.png')} />
                    </div>

                    <div className="col-6 col-md-3 border-box border-0-bottom">
                        <img draggable="false" className="greyscale m-5 p-1" width="170" src={require('./../../Assets/Images/Technologies/prestashop.png')} />
                    </div>

                    <div className="col-6 col-md-3 border-box border-0-bottom">
                        <img draggable="false" className="greyscale m-5 p-1" width="170" src={require('./../../Assets/Images/Technologies/wordpress.png')} />
                    </div>

                    <div className="col-6 col-md-3 border-box border-0-bottom border-0-right">
                        <img draggable="false" className="greyscale m-5 p-1" width="170" src={require('./../../Assets/Images/Technologies/jquery.png')} />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TechnologiesComponent;
