import './index.css';
import React, {useRef} from 'react';
import Tilt from 'react-parallax-tilt';
import {AnimationOnScroll} from "react-animation-on-scroll";

const IphoneComponent = () => {

    return (
        <div className="px-2">
            <div className="row my-0 my-lg-5 pt-0 pt-lg-5">
                <div className="small gradient-text-top-bottom-purple">CUM CONSTRUIM</div>
                <div className="my-4">
                    <h2>Abordarea Snap Design</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-3 text-sm-center text-md-end">

                    <div className="mb-5 w-100">
                        <img width="64" src={require('../../Assets/Images/Icons/Approach/user.png')}/>
                        <div className="mt-2 cera-pro-medium">
                            Design centrat pe utilizator
                        </div>
                        <div className="mt-2">
                            Prioritizam experienta utilizatorului prin intelegerea nevoilor si obiectivelor lor.
                        </div>
                    </div>

                    <div className="mb-5 w-100">
                        <img width="64" className="mt-4" src={require('../../Assets/Images/Icons/Approach/custom.png')}/>
                        <div className="mt-2 cera-pro-medium">
                            Solutii personalizate
                        </div>
                        <div className="mt-2">
                            Abordam fiecare proiect intr-un mod unic, adaptandu-ne la cerintele clientilor.
                        </div>
                    </div>

                    <div className="mb-5 w-100">
                        <img width="64" className="mt-4" src={require('../../Assets/Images/Icons/Approach/colaborative.png')}/>
                        <div className="mt-2 cera-pro-medium">
                            Abordare colaborativa
                        </div>
                        <div className="mt-2">
                            Lucram impreuna cu clientii nostri pentru a obtine cele mai bune rezultate.
                        </div>
                    </div>

                </div>
                <div className="col-sm-12 col-md-6 d-flex flex-wrap justify-content-center p-5">
                    <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce duration="0.5">
                    <div className="iphone-wrapper">
                        <Tilt tiltMaxAngleX={7} tiltMaxAngleY={5} perspective={1000} >
                            <div className="iphonex">
                                <div className="front iphone-shadow">
                                    <div className="screen iphone-inner-shadow ">
                                        <div className="screen__view">
                                            <div>
                                                <img width="50" src={require('./../../Assets/Images/logo_gear.png')}/>
                                            </div>
                                            <div className="hello">
                                                <p>SnapDesign</p>
                                            </div>
                                        </div>
                                        <div className="screen__front">
                                            <div className="screen__front-speaker"></div>
                                            <div className="screen__front-camera"></div>
                                        </div>
                                    </div>
                                    <div className="front__line"></div>
                                    <div className="front__line front__line-second"></div>
                                </div>
                                <div className="phoneButtons phoneButtons-right"></div>
                                <div className="phoneButtons phoneButtons-left"></div>
                                <div className="phoneButtons phoneButtons-left2"></div>
                                <div className="phoneButtons phoneButtons-left3"></div>
                                <div className="back"></div>
                            </div>
                        </Tilt>
                    </div>
                    </AnimationOnScroll>

                </div>
                <div className="col-sm-12 col-md-3 text-sm-center text-md-start">

                    <div className="mb-5 w-100">
                        <img width="64" src={require('../../Assets/Images/Icons/Approach/flexible.png')}/>
                        <div className="mt-2 cera-pro-medium">
                            Metodologie flexibila
                        </div>
                        <div className="mt-2">
                            Folosim o metodologie adaptabila pentru a ne asigura ca proiectele noastre sunt livrate cu succes.
                        </div>
                    </div>

                    <div className="mb-5 w-100">
                        <img width="64" className="mt-4" src={require('../../Assets/Images/Icons/Approach/details.png')}/>
                        <div className="mt-2 cera-pro-medium">
                            Atentie la detalii
                        </div>
                        <div className="mt-2">
                            Acordam o atentie deosebita detaliilor pentru a oferi site-uri web de calitate superioara.
                        </div>
                    </div>

                    <div className="mb-5 w-100">
                        <img width="64" className="mt-4" src={require('../../Assets/Images/Icons/Approach/support.png')}/>
                        <div className="mt-2 cera-pro-medium">
                            Suport dupa lansare
                        </div>
                        <div className="mt-2">
                            Asiguram suport si mentenanta continua dupa lansarea site-ului web.
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default IphoneComponent;
