import './index.css';
import React from 'react';
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import WelcomeComponent from "../../Components/Welcome";
import WideContentComponent from "../../Components/WideContent";
import PowerhousesComponent from "../../Components/Powerhouses";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ServicesPage = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Servicii WebDesign si Development</title>
                <meta name="description" content="Snap Design ofera o gama completa de servicii de web design si dezvoltare pentru site-uri de prezentare, magazine online, incluzand design grafic, dezvoltare web personalizata, dezvoltare de aplicatii web, optimizare pentru motoarele de cautare (SEO) si multe altele. Echipa noastra lucreaza indeaproape cu fiecare client pentru a intelege obiectivele si nevoile unice si pentru a furniza solutii personalizate care sa satisfaca cerintele fiecarui proiect." />
                <link rel="canonical" href="/servicii" />
            </Helmet>
            <Navbar />
            <Header
                smallTitle='Snap Design'
                bigTitle='SERVICII'
                description='Web Design & Development'
            />

            <div className="container mt-5 text-end mobile-hidden animate__animated animate__fadeInUp pt-5">
                <span className="font-24 gradient-text-top-bottom-purple px-4 cera-pro-medium">Dezvoltare Web</span>
                <span className="font-64 number-holder">1</span>
            </div>

            <div className="container my-5 my-md-4 py-5 animate__animated animate__fadeInLeft">
                <WelcomeComponent
                    titleBig='Servicii de web development'
                    titleMedium='Dezvoltam site-uri web performante si securizate, adaptate nevoilor clienților.'
                    content={['Echipa noastra de dezvoltatori experimentati utilizeaza cele mai recente tehnologii si tendinte pentru a crea site-uri web rapide, scalabile si usor de utilizat. Oferim, de asemenea, servicii de integrare si dezvoltare API, pentru a permite site-ului sa se integreze perfect cu alte sisteme sau aplicatii de care afacerea ta are nevoie.', <span className="d-block mt-3">Daca esti in cautarea unei echipe de dezvoltare web care poate oferi solutii personalizate si scalabile, atunci este in locul potrivit. La Snap Design, suntem dedicati sa ajutam afacerile sa-si atinga obiectivele, indiferent de dimensiunea sau complexitatea proiectului. Contactati-ne pentru a discuta despre proiectul dvs. de dezvoltare web.</span>]}
                />
            </div>

            <AnimationOnScroll animateIn="animate__zoomIn" animateOnce duration="0.5">
                <div className="container my-5 d-flex flex-wrap justify-content-center">
                    <div className="d-block">
                        <img src={require('./../../Assets/Images/Content/development.png')} draggable="false" style={{ width: "100%" }} />
                    </div>
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.5">
                <div className="container mt-5 text-start mobile-hidden pt-5">
                    <span className="font-64 number-holder">2</span>
                    <span className="font-24 gradient-text-top-bottom-purple px-4 cera-pro-medium">Design Web</span>
                </div>
            </AnimationOnScroll>


            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce duration="0.5">
                <div className="container my-4 py-5">
                    <WelcomeComponent
                        titleBig='Servicii de web design'
                        titleMedium='Cream site-uri web unice si memorabile, cu atentie la detalii.'
                        content={['La Snap Design, ne specializam in crearea de site-uri web personalizate, responsive si user-friendly care se adapteaza nevoilor unice ale clientilor noștri. Echipa noastra de designeri si dezvoltatori experimentati lucreaza impreuna pentru a crea site-uri web impresionante care ofera o experienta fluida utilizatorilor. Acordam o atentie deosebita fiecarui detaliu, de la structura si tipografia site-ului pana la paleta de culori si imaginile folosite, pentru a ne asigura ca site-ul reflecta cu adevarat brandul si viziunea clientului.', <span className="mt-3 d-block">Credem ca fiecare site web ar trebui sa fie o unealta puternica care ajuta afacerile sa-si atinga obiectivele, indiferent daca este vorba de cresterea notorietatii brandului, generarea de lead-uri sau vanzarea de produse si servicii.</span>]}
                    />
                </div>
            </AnimationOnScroll>

            <div className="container my-md-5 py-md-5 d-flex flex-wrap justify-content-center">
                <div className="row">
                    <div className="col-md-4">
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3">
                            <img className="px-4 py-3 py-md-0 px-md-0" src={require('./../../Assets/Images/Content/design_1.png')} draggable="false" style={{ width: "100%" }} />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-md-4">
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce delay="300" duration="0.3">
                            <img className="px-4 py-3 py-md-0 px-md-0" src={require('./../../Assets/Images/Content/design_2.png')} draggable="false" style={{ width: "100%" }} />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-md-4">
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce delay="600" duration="0.3">
                            <img className="px-4 py-3 py-md-0 px-md-0" src={require('./../../Assets/Images/Content/design_3.png')} draggable="false" style={{ width: "100%" }} />
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.5">
                <div className="container mt-5 text-end mobile-hidden pt-5">
                    <span className="font-24 gradient-text-top-bottom-purple px-4 cera-pro-medium">Magazin Online</span>
                    <span className="font-64 number-holder">3</span>
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce duration="0.5">
                <div className="container my-5 py-1 py-md-5 my-md-4 py-md-5">
                    <WelcomeComponent
                        titleBig='Servicii creare Online Shop'
                        titleMedium='Maximizeaza-ti vanzarile cu un magazin online modern, user-friendly.'
                        content={['Construirea unui magazin online cu SnapDesign are numeroase beneficii. Echipa noastra de dezvoltatori web cu experienta va crea un magazin profesional, ușor de utilizat, care este adaptat nevoilor tale, specifice afacerii. Utilizam cea mai recenta tehnologie pentru a ne asigura ca magazinul dvs. este rapid, sigur si capabil sa gestioneze un volum mare de tranzactii.', <span className="d-block mt-3">Designul nostru receptiv asigura ca magazinul dvs. arata grozav si functioneaza perfect pe toate dispozitivele. Permite-ne sa te ajutam sa iti duci afacerea la urmatorul nivel cu un magazin online de cea mai buna calitate.</span>]}
                    />
                </div>
            </AnimationOnScroll>

            <div className="container my-md-3 py-md-5 d-flex flex-wrap justify-content-center">
                <div className="row">
                    <div className="col-md-4">
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.3">
                            <img className="px-4 py-3 py-md-0 px-md-0" src={require('./../../Assets/Images/Content/shop_1.png')} draggable="false" style={{ width: "100%" }} />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-md-4">
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce delay="300" duration="0.3">
                            <img className="px-4 py-3 py-md-0 px-md-0" src={require('./../../Assets/Images/Content/shop_2.png')} draggable="false" style={{ width: "100%" }} />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-md-4">
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce delay="600" duration="0.3">
                            <img className="px-4 py-3 py-md-0 px-md-0" src={require('./../../Assets/Images/Content/shop_3.png')} draggable="false" style={{ width: "100%" }} />
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>

            <PowerhousesComponent />

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.5">
                <div className="container mt-md-5 pt-md-5 text-start mobile-hidden">
                    <span className="font-64 number-holder">4</span>
                    <span className="font-24 gradient-text-top-bottom-purple px-4 cera-pro-medium">Optimizare Motoare Cautare</span>
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce duration="0.5">
                <div className="container my-5 py-1 py-md-5 my-md-4 py-md-5">
                    <WelcomeComponent
                        titleBig='Servicii de optimizare SEO'
                        titleMedium='Maximizam vizibilitatea si traficul site-urilor web prin optimizare SEO.'
                        content={['In ultimii ani motoarele de cautare precum Google, Yahoo s.a. au lasanat algoritmi pentru cautarea paginilor web. Acesti algoritmi au trecut prin numeroase upgrade-uri si inovatii pentru o mai buna experienta a utilizatorilor, de aceea este necesar ca site-ul dvs. sa fie optimizat si actualizat pentru noile standarde in procesul de indexare a paginilor.', <span className="d-block mt-3"> SnapDesign va ofera servicii de optimizare site-ului dvs. pentru o vizibilitate cat mai buna in cele mai populare motoare de cautare.</span>]}
                    />
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__zoomIn" animateOnce duration="0.5">
                <div className="container d-flex flex-wrap justify-content-center pb-5">
                    <div className="d-block">
                        <img src={require('./../../Assets/Images/Content/seo.png')} draggable="false" style={{ width: "80%" }} />
                    </div>
                </div>
            </AnimationOnScroll>

            <div className="bg-light-gray my-5 py-5">
                <div className="container pb-5 my-4">
                    <WideContentComponent
                        smallTitle='DE CE SA LUCREZI CU'
                        bigTitle='Snap Design?'
                        description='Site-ul dvs. trebuie sa fie vizibil pe motoarele de cautare precum Google, Bing, Yahoo etc! Peste 95% din experiențele online încep cu un motor de căutare, astfel încât site-ul dvs. web trebuie să fie optimizat. Când alegeți SnapDesign pentru proiectarea site-ului dvs, vă este garantat un site web optimizat pentru SEO.'
                    />
                    <div className="d-flex flex-wrap justify-content-center mt-5">
                        <Link to="/contact" className="action-button">Contacteaza-ne</Link>
                    </div>
                </div>
            </div>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce duration="0.5">
                <div className="container mt-5 text-end mobile-hidden pt-5">
                    <span className="font-24 gradient-text-top-bottom-purple px-4 cera-pro-medium">Publicitate si Online Marketing</span>
                    <span className="font-64 number-holder">5</span>
                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce duration="0.5">
                <div className="container mb-5 py-5">
                    <WelcomeComponent
                        titleBig='Servicii de online marketing'
                        titleMedium='Oferim solutii de marketing online personalizate, pentru cresterea conversiilor.'
                        content={['In ultimii 12 ani strategiile de marketing au evoluat mai mult decat in ultimii 100 de ani, astfel marketingul online a capatat o popularitate foarte ridicata. Consumatorii din ziua de astazi sunt conectati la Internet 24/7, consumand continut media aproximativ 6 ore pe zi, cu exceptia joburile dependente de Internet.', <span className="d-block mt-3"> Cu ajutorul serviciilor noastre de online marketing iti poti promova produsele / serviciile in mediul online pentru a atrage un public tinta mult mai larg.</span>]}
                    />
                </div>
            </AnimationOnScroll>

            <div className="container">
                <Footer />
            </div>
        </div>
    );
};

export default ServicesPage;
