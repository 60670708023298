import './index.css';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { getCalApi } from "@calcom/embed-react";

const NavBar = () => {

    useEffect(() => {
        (async function () {
            const Cal = await getCalApi();
            (function (C, A, L) { let p = function (a, ar) { a.q.push(ar); }; let d = C.document; C.Cal = C.Cal || function () { let cal = C.Cal; let ar = arguments; if (!cal.loaded) { cal.ns = {}; cal.q = cal.q || []; d.head.appendChild(d.createElement("script")).src = A; cal.loaded = true; } if (ar[0] === L) { const api = function () { p(api, arguments); }; const namespace = ar[1]; api.q = api.q || []; typeof namespace === "string" ? (cal.ns[namespace] = api) && p(api, ar) : p(cal, ar); return; } p(cal, ar); }; })(window, "https://app.cal.com/embed/embed.js", "init");
            Cal("init", "15min", { origin: "https://cal.com" });

            Cal.ns["15min"]("floatingButton", { "calLink": "snapdesign/15min", "buttonColor": "#8855da", "buttonPosition": "bottom-right", "buttonText": "Fa-ti o programare" });
            Cal.ns["15min"]("ui", { "theme": "light", "styles": { "branding": { "brandColor": "#8855da" } }, "hideEventTypeDetails": false, "layout": "month_view" });
        })();
    }, []);

    return (

        <div>

            <nav className="navbar navbar-expand-lg navbar-dark bg-navbar">
                <div className="container-fluid container navbar-width">
                    <Link className="navbar-brand" to="/">
                        <div className="d-flex flex-wrap align-items-center">
                            <img src={require('./../../Assets/Images/logo_gear.png')} height="32" className="d-inline" style={{ "marginTop": "-8px" }} />
                            <h2 className="nav-brand-text cera-pro-light text-white d-inline px-1">SnapDesign</h2>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-start justify-content-md-end" id="navbarSupportedContent">
                        <div>
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item px-2">
                                    <Link className="nav-link nav-link-font" to="/">Acasa</Link>
                                </li>
                                <li className="nav-item px-2">
                                    <Link className="nav-link nav-link-font" to="/servicii">Servicii</Link>
                                </li>
                                <li className="nav-item px-2">
                                    <Link className="nav-link nav-link-font" to="/portofoliu">Portofoliu</Link>
                                </li>
                                {/*<li className="nav-item px-2">*/}
                                {/*    <Link className="nav-link nav-link-font" to="/produse">Produse</Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item px-2">*/}
                                {/*    <Link className="nav-link nav-link-font" to="/studii-de-caz">Studii de caz</Link>*/}
                                {/*</li>*/}
                                <li className="nav-item px-2">
                                    <Link className="nav-link nav-link-font" to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </div>
    );
};

export default NavBar;
